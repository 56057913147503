<template>
  <div class="p-wrap">
    <p-header class="p-header" spacer title="底稿调整记录">
      <el-button type="default" @click="handleBack">返回</el-button>
    </p-header>
    <div class="p-content">
      <el-table :data="records"
                border
                stripe
                :header-cell-style="handleHeaderCellStyle"
                style="width: 100%;margin-bottom: 20px;">
        <el-table-column type="index" label="序号" width="60"/>
        <el-table-column prop="sheetName" label="Sheet页名" show-overflow-tooltip width="160"/>
        <el-table-column prop="position" label="单元格位置" width="100" :formatter="fmtPosition"/>
        <el-table-column prop="cellName" label="单元格名称" width="100" :formatter="fmtPosition"/>
        <el-table-column prop="historyValue" label="调整前" show-overflow-tooltip width="400"/>
        <el-table-column prop="currentValue" label="调整后" show-overflow-tooltip min-width="400"/>
        <el-table-column prop="reason" label="调整原因" width="240">
          <template slot-scope="scope">
            <el-input v-model="scope.row.reason" @blur="handleBlurInput(scope.row)"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div class="p-page">
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleQuery"
                       :current-page.sync="filter.page"
                       :page-sizes="[15,30,50,100]"
                       :page-size="filter.size"
                       layout="total, sizes, prev, pager, next"
                       :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import PHeader from '@/components/pdt/PHeader'
import { tableStyle } from '@/util/mixins'
import { listTmplHistory, updateTmplHistory } from '@/service/declare/index'

export default {
  name: 'TmplHistory',
  components: { PHeader },
  mixins: [tableStyle],
  data () {
    return {
      // 筛选条件
      filter: {
        declareTmplId: this.$route.query.declareTmplId,
        page: 1,
        size: 15
      },
      // 列表数据
      records: [],
      total: 0,
    }
  },
  created () {
    this.handleQuery()
  },
  methods: {
    // 列表查询
    async handleQuery (val) {
      if (val) this.filter.page = val
      const { success, data } = await listTmplHistory(this.filter)
      if (success) {
        this.records = data.records
        this.total = data.total
      }
    },
    // 分页条数切换
    handleSizeChange (val) {
      this.filter.size = val
      this.handleQuery()
    },
    // 格式化定位
    fmtPosition (row) {
      return `${String.fromCharCode(row.c + 65)}${row.r}`
    },
    // 失去焦点
    async handleBlurInput (row) {
      const { success } = await updateTmplHistory(row)
      if (success) this.toast('底稿记录调整成功', 'success')
    },
    // 返回
    handleBack () {
      this.$router.push({
        path: '/declare/ent/tmpl/detail',
        query: { id: atob(this.filter.declareTmplId) }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.p-header {
  width: auto;
  background-color: #fff;
  padding-left: 24px;
  padding-right: 24px;
}
</style>